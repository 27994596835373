.app-page {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 24px;
    .title-page {
        font-size: 28px;
        text-align: center;
        margin-bottom: 40px;
    }
    .content-page {
        display: flex;
        gap: 24px;
    }
    .form-upload {
        flex: 1;
        .form-item {
            margin-bottom: 16px;
            label {
                display: block;
                margin-bottom: 2px;
            }
            .input-text {
                width: 100%;
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 8px;
            }
        }
    }
    .qr-review {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: center;
        canvas {
            width: 100%;
            height: 100%;
            max-width: 300px;
            max-height: 300px;
        }
    }
    .btn-action {
        border: none;
        background: #ff8900;
        color: #fff;
        padding: 8px 24px;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 700;
    }
}
