@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 100%;
}

html {
    --color-primary: #fecd2f;
    --color-dark-orange: #ff8900;
    --color-secondary: #6f68f8;
    --color-notice: #fc2569;
    --color-border: #2a2a57;
    --color-account-bg: #0f0f33;
}

body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
